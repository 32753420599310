import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

export default withAuth(class User extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null
    }
  }

  async handleSubmit(email) {
    this.setState({user: undefined});
    try {
      const response = await fetch('https://cwn3ouk928.execute-api.us-east-1.amazonaws.com/dev', {
        method: 'POST',
        headers: {
          'Authorization' : 'Bearer ' + await this.props.auth.getAccessToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: email})
      });
      const data = await response.json();
      console.log(JSON.parse(data.body).user);
      this.setState({ user: JSON.parse(data.body).user });
    } catch (err) {
      // handle error as needed
      console.log(err);
    }
  };

  render() {
    return (
      <>
      <br></br>
      <h1> Lookup Account </h1>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={async values => {
          this.handleSubmit(values.email);
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required("Required")
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset
          } = props;
          return (
            <form onSubmit={handleSubmit} className='form'>
              <fieldset className='fieldset'>
                <label htmlFor="email" style={{ display: "block" }}>
                  Email
                </label>
                <input
                  id="email"
                  placeholder="Enter your email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? "text-input error field--error"
                      : "text-input"
                  }
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </fieldset>
              <section className="form--actions">
                <button
                  type="button"
                  className="button is-button-secondary outline "
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  Reset
                </button>
                <button className="button" type="submit" disabled={isSubmitting}>
                  Submit
                </button>
                </section>
              </form>
          );
        }}
      </Formik>
      </>
    );
  };
});