import React, { Component } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import Title from './Title';

const COLORS = { fail: '#f44336', pass: '#4caf50', warn: '#ff9800' }

const calcWheelColor = (percentage, smallerIsBetter) => {
  if (smallerIsBetter) {
    if (percentage >= 0.75) {
     return COLORS.fail;
    } else if (percentage >= 0.5) {
      return COLORS.warn;
    }
    if (percentage >= 1) {
      return COLORS.fail;
    }
    return COLORS.pass;
  } else {
    if (percentage >= 0.75) {
      return COLORS.pass;;
    } else if (percentage >= 0.5) {
      return COLORS.warn;
    }
    return COLORS.fail;
  }
}

const passFailColor = (didFail) => {
  if(didFail) return COLORS.fail;
  else return COLORS.pass;
}

const passFailLabel = (didFail) => {
  if(didFail) return 'fail';
  else return 'pass';
}

const cellFillColor = (percentage, entry, smallerIsBetter) => {
  if(entry.name === 'passed') {
    return calcWheelColor(percentage/100, smallerIsBetter);
  } else {
    return '#e0e0e0';
  }
}

export default class Chart extends Component  {
  render() {
    const result = this.props.section.tests;
    result.smallerIsBetter = false;
    const data = [{name: 'failed', value: result.total - result.passed }, { name: 'passed', value: result.passed }];

    const percentage = (result.passed/result.total*100).toFixed(0);
    const wheelColor = calcWheelColor(percentage/100, false);
    return (
      <>
        <Title>{this.props.section.label}</Title>
        <ResponsiveContainer>
          <PieChart width={200} height={100} /*onMouseEnter={this.onPieEnter}*/>
            <text x={125} y={60} dy={10} textAnchor="middle" fill={wheelColor}>{percentage}%</text>
            <text x={125} y={60} dy={80} textAnchor="middle" fill={wheelColor}>{result.passed}/{result.total}</text>
            <text x={125} y={60} dy={100} textAnchor="middle" fill={passFailColor(result.mandatoryfail)}>{passFailLabel(result.mandatoryfail).toUpperCase()}</text>
            <Pie
              data={data}
              cx={120}
              cy={60}
              innerRadius={40}
              outerRadius={60}
              fill="#8884d8"
              paddingAngle={0}
            >
              {
                data.map((entry, index) => <Cell fill={cellFillColor(percentage, entry, false)}/>)
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </>
    );
  }
}