import React, { Component } from 'react';

export default class Accounts extends Component {
  render() {
    const accounts = this.props.accounts.map((account, index) =>
      <Account account={account} key={index}></Account>
    );
    return (
      [accounts]
    )
  }
};

class Account extends Component {
  render() {
    const account = this.props.account;
    return (
      <div className='card'>
        <div className='card--main card--list'>
          <section key={account.system.toString() + this.props.index}>
            System: {account.system.toString()}
          </section>
          <section key={account.instanceName.toString() + this.props.index}>
            Instance name: {account.instanceName.toString()}
          </section>
          <section key={account.environment.toString() + this.props.index}>
            Environment: {account.environment.toString()}
          </section>
          <section key={account.isActive.toString() + this.props.index}>
            Active: {account.isActive.toString()}
          </section>
        </div>
      </div>
    );
  }
}