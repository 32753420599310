import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StarIcon from '@material-ui/icons/Star';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Title from './Title';

const displayValue = (value) => {
  if(Array.isArray(value)) {
    return value.map((hint, index) => (
      <ul key={index}>
        <li>{hint}</li>
      </ul> ));
  }
  return value;
}

const displayMandatory = (isMandatory) => {
  if(isMandatory) return <StarIcon></StarIcon>
}

const COLORS = { fail: '#f44336', pass: '#4caf50', warn: '#ff9800' };

const passFailColor = (didFail) => {
  if(didFail) return COLORS.fail;
  else return COLORS.pass;
}

const passFailIcon = (didFail) => {
  const color = passFailColor(didFail);
  if(didFail) return <NotInterestedIcon style={{color: color}}></NotInterestedIcon>
  return <CheckCircleIcon style={{color: color}}></CheckCircleIcon>
}

const SectionDetails = (props) => {
  const rows = props.section.lines
  return (
    <React.Fragment>
      <Title>{props.section.title}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{fontWeight: 'bold'}}>Mandatory</TableCell>
            <TableCell  style={{fontWeight: 'bold'}}>Label</TableCell>
            <TableCell style={{fontWeight: 'bold'}}>Pass/Fail</TableCell>
            <TableCell style={{fontWeight: 'bold'}}>Result</TableCell>
            <TableCell style={{fontWeight: 'bold'}}>Result Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{displayMandatory(row.isMandatory)}</TableCell>
              <TableCell>{row.label}</TableCell>
              <TableCell>{passFailIcon(!row.isValid)}</TableCell>
              <TableCell>{displayValue(row.value)}</TableCell>
              <TableCell>{row.hint} <br></br> <a href={row.ref}>{row.ref}</a></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export default SectionDetails;