import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import UserProfile from './UserProfile';
import Accounts from './Accounts';
import UserForm from './UserForm';

export default withAuth(class User extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null
    }
  }

  render() {
    if (!this.state.user) {
      return (
        <>
          <div>
            <section>
              <UserForm></UserForm>
            </section>
          </div>
        </>
      );
    } else {
      const user = JSON.parse(this.state.user);
      return (
        <div>
          <section>
            <UserForm></UserForm>
          </section>
          <section>
            <UserProfile email={user.email}></UserProfile>
            <Accounts accounts={user.list}></Accounts>
          </section>
        </div>
      )
    }
  }
});