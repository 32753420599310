import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';
import Home from './Home';
import ActiveUsersList from './User';
import CoveoDashboard from './Coveo/Dashboard';

const config = {
  issuer: 'https://nate-dev.clouditude.com/oauth2/default',
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: '0oa329j7BAavB9XOA405',
  scopes: ['openid', 'profile', 'email', 'api:admin-tools'],
  pkce: true
}

class App extends Component {
  render() {
    return (
      <Router>
        <Security {...config}>
          <Route path='/' exact={true} component={Home}/>
          <Route path='/implicit/callback' component={ImplicitCallback}/>
          <SecureRoute path='/activeUsers' component={ActiveUsersList} />
          <SecureRoute path='/coveo/dashboard' component={CoveoDashboard}></SecureRoute>
        </Security>
      </Router>
    );
  }
}

export default App;
