import React, { Component } from 'react';

export default class User extends Component {
  render() {
    console.log(this.props.email);
    return (
      <div>
        <h2 >Email: {this.props.email} </h2>
      </div>
    )
  }
};